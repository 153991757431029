//
// Nav Areas CSS
//

.nav-areas {
  position: absolute;
  top: 324px;
  right: 7.8125%; // 150px
  z-index: 5;
  width: 700px;
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    counter-reset: li-counter;
    li {
      margin-bottom: 20px;
      float: left;
      width: 340px;
      margin-left: 5px;
      a {
        display: block;
        position: relative;
        text-decoration: none;
        color: $white;
        @include font-size(35);
        line-height: 100px;
        span {
          display: block;
          &.title {
            padding: 0 20px 0 110px;
            background: $blue;
            border-radius: 10px 10px 0 0;
          }
          &.description {
            background: $blue-light;
            @include font-size(20);
            padding: 10px 20px 20px 50px;
            height: 100px;
            margin-top: 1px;
            line-height: 1.2;
            border-radius: 0 0 10px 10px;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          background: $grey-mid;
          width: 90px;
          text-align: center;
          content: counter(li-counter, decimal-leading-zero);
          counter-increment: li-counter;
          border-radius: 10px 0 0 0;
        }
        &:hover span.title {
          background: $yellow;
        }
      }
    }
  }
  @media only screen and (max-width: $size-XL) {
    right: auto;
    top: auto;
    left: 50%;
    padding-top: 20px;
    transform: translate(-50%, 0);
  }
  @media only screen and (max-width: $size-M) {
    position: relative;
    left: auto;
    transform: none;
    width: auto;
    padding: 20px 10px 0 10px;
    ol li {
      float: none;
      width: auto;
      margin-left: 0;
    }
  }
}

// Dokumentationszentrum
.documentation-center .nav-areas ol li a {
  span {
    &.title {
      background: $blue-dark;
    }
    &.description {
      background: $blue-plain;
    }
  }
}

// Tourismus
.tourism .nav-areas ol li a {
  span {
    &.title {
      background: #0ead99;
    }
    &.description {
      background: #669292;
    }
  }
}