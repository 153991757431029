//
// Tourism CSS
//

#map,
#map-canvas {
  width: 100%;
  height: calc(100vh - 40px) !important;
}

.leaflet-popup-content {
  font-family: $baseFont;
  @include font-size(16);

  .tourism-location-title {
    font-weight: 700;
  }

  a.more {
    position: relative;
    display: inline-block;
    //position: absolute;
    //right: 0;
    //bottom: 0;
    margin-top: 15px;
    background: #404049;
    color: $white;
    text-decoration: none;
    //border-radius: 15px 0 0;
    padding: 2px 40px 2px 15px;
    @include font-size(16);
    &:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 3px;
      height: 20px;
      width: 20px;
      background: url("../Images/arrow_right.svg") no-repeat 0 center;
      background-size: 14px;
    }
    &:hover {
      background-color: $red;
    }
  }

}

.gm-style {
  img {
    max-width: none;
  }
}

.gm-style-iw {
  width: 200px;
  min-height: 50px;
  a.more {
    display: block;
    //position: absolute;
    //right: 0;
    //bottom: 0;
    margin-top: 15px;
    float: right;
    background: #404049;
    color: $white;
    text-decoration: none;
    //border-radius: 15px 0 0;
    padding: 2px 40px 2px 15px;
    @include font-size(20);
    &:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 20px;
      height: 20px;
      width: 20px;
      background: url("../Images/arrow_right.svg") no-repeat 0 center;
      background-size: 20px;
    }
    &:hover {
      background-color: $red;
    }
  }
}

.info-box-window {
  width: 180px;
  min-height: 50px;
}


// distance filter
.distance-filter {
  position: absolute;
  top: 52px;
  left: 60px;
  z-index: 999;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      background-color: $green-mind;
      background-size: 100% 100%;
      border-radius: 5px;

      &.walking {
        background-image: url("../Images/walking.svg");
      }
      &.bike {
        background-image: url("../Images/bike.svg");
      }
      &.car {
        background-image: url("../Images/car.svg");
      }

      &:hover {
        background-color: $red;
      }
    }
  }

  @media only screen and (max-width: $size-S2) {
    ul li {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: $size-XS) {
    ul li {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}

// category filter
.category-filter {
  position: absolute;
  top: 112px;
  left: 60px;
  z-index: 999;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
      float: left;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      overflow: hidden;
      text-indent: -99999px;
      &.restaurant {
        background: url("../Images/restaurant.svg") 100%/100%;
      }
      &.hotel {
        background: url("../Images/hotel.svg") 100%/100%;
      }
      &.sightseeing {
        background: url("../Images/sightseeing.svg") 100%/100%;
      }
      &:hover {
        &.restaurant {
          background: url("../Images/restaurant_act.svg") 100%/100%;
        }
        &.hotel {
          background: url("../Images/hotel_act.svg") 100%/100%;
        }
        &.sightseeing {
          background: url("../Images/sightseeing_act.svg") 100%/100%;
        }
      }
    }
  }

  @media only screen and (max-width: $size-S2) {
    top: 110px;
    ul li {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
  }

  @media only screen and (max-width: $size-XS) {
    top: 100px;
    ul li {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}

// modified iframe version
body.iframe {

  #map {
    width: 100%;
    height: 100vh !important;
  }

  .distance-filter {
    top: 12px;
  }

  .category-filter {
    top: 72px;
  }
}




// detail

.tourism-content {
  padding: 20px 60px 40px 60px;
  display: flex;

  .tourism-text {
    width: 60%;
    padding-left: 40px;
    color: $white;
  }

  .tourism-images {
    width: 40%;
  }
}