//
// Web Fonts
//

/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
  url('../Fonts/open-sans-condensed-v13-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Fonts/open-sans-condensed-v13-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-condensed-300italic - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Condensed Light Italic'), local('OpenSansCondensed-LightItalic'),
  url('../Fonts/open-sans-condensed-v13-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Fonts/open-sans-condensed-v13-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'),
  url('../Fonts/open-sans-condensed-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Fonts/open-sans-condensed-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}