//
// Footer CSS
//

.page-footer {

  position: fixed;
  top: 0;
  right: 0;
  padding: 10px 210px 0 0;
  z-index: 9999;

  @media only screen and (max-width: $size-S) {
    padding-right: 130px;
  }

}
