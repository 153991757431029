//
// Project Color Definitions
//

$grey: #25282A;
$grey-mid: #404049;
$blue: #48647c;
$blue-light: #708ca3;
$blue-plain: #3598db;
$blue-dark: #2567b0;
$yellow: #FBD062;
$green-mind: #548687;
$green-light: #c9d7c2;
$red: #FD6864;
$red-dark: #A21D34;
$white: #FFFFFF;
$black: #000000;

$elearning: #56839D;
$history: #5FB5CE;
$tourism: #15AC99;
$documentation: #2367AA;