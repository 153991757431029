//
// Nav Footer CSS
//

.nav-footer {

  ul {
    @include ul-reset;
    display: flex;
    justify-content: center;
  }

  li {
    padding: 0 8px;
  }

  a {
    text-decoration: none;
    color: #CCCCCC;
    @include font-size(14);
  }

}