//
// Nav Main CSS
//

.nav-main {
  padding: 0 20px;

  a.home {
    display: block;
    /*
    background: url("../Images/home.svg") no-repeat 100%/100%;
    text-indent: -9999px;
    */
    width: 37px;
    height: 34px;
    float: left;
    margin-top: 3px;

    svg {
      width: 37px;
      height: 34px;
    }

    &:hover {
      //background-image: url("../Images/home_act.svg");
      svg g {
        stroke: $red;
      }
    }
  }
  a.back {
    display: block;
    //background: url("../Images/arrow_up.svg") no-repeat center top 100%/100%;
    //text-indent: -9999px;
    width: 28px;
    height: 34px;
    float: left;
    margin-left: 20px;
    margin-top: 3px;

    svg {
      width: 28px;
      height: 34px;
    }

    &:hover {
      //background-image: url("../Images/arrow_up_act.svg");

      svg path{
        fill: $red;
      }
    }
  }


  ul {
    @include ul-reset;
    display: flex;
    margin-left: 150px;

    li {
      padding: 1px;

      a {
        display: block;
        line-height: 38px;
        width: 180px;
        color: $white;
        text-align: center;
        background: $green-mind;
        @include font-size(20);
        text-decoration: none;

        &:hover {
          background: $red;
        }
      }
    }

    @media only screen and (max-width: $size-S) {
      margin-left: 110px;
    }
  }

}


// hide nav main on page Lebendiges Vohburg
.page-id-22 .nav-main ul {
  display: none;
}

// hide back button on legal pages (as there is only 1 level up to home)
.pid-6 .nav-main {
  #icon-back {
    display: none;
  }
}