//
// Defaul Project Settings
//

$maxPageWidth: 1920px;
$baseFont: 'Open Sans Condensed', sans-serif;

// breakpoints
$size-XXXL: 1919px;
$size-XXL2: 1650px;
$size-XXL: 1350px;
$size-XL2: 1150px;
$size-XL: 1000px;
$size-L: 870px;
$size-M: 767px;
$size-S2: 600px;
$size-S: 500px;
$size-XS: 400px;