//
// historical buildings special
//

.history-list-view-special {
  padding-top: 80px;

  .slider-not-initialized {

    figure {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $size-S2) {

      display: flex;
      flex-flow: row wrap;
      margin: -20px;
      padding: 0 40px;

      figure {
        width: 50%;
        padding: 20px;
        margin: 0;
      }

    }

  }
}

.history-detail-view-special {

  h1 {
    margin-bottom: 20px;
  }

  &-main {
    display: flex;
    padding: 0 60px;
  }

  header {
    color: white;
    padding: 20px 30px 20px 40px;
  }

  .history-detail-images {
    width: 50%;

    figcaption {
      color: $white;
      padding: 5px 0 0 0;
    }
  }

  .history-detail-image {
    margin-bottom: 40px;
  }

  .history-image-thumbs {

    ul {
      @include ul-reset;
      margin: -1px;
      display: flex;
      flex-flow: row wrap;

      li {
        padding: 1px;

        img {
          display: block;
        }
      }
    }
  }

  .history-detail-content {
    padding-left: 80px;
    color: $white;
    width: 50%;

    .ce-default {
      margin-top: 15px;
      &:first-child {
        margin: 0;
      }
    }
    h2 {
      @include font-size(34);
      margin: 0 0 15px;
      font-weight: 300;
    }
    h3 {
      @include font-size(28);
      margin: 0;
      font-weight: 300;
    }
    h4 {
      font-weight: 300;
    }
    p {
      @include font-size(16);
      margin: 0;
    }
  }


  @media only screen and (max-width: $size-L) {

    &-main {
      display: block;
    }

    .history-detail-images {
      width: auto;
      margin-bottom: 40px;
      padding-left: 0;
    }

    .history-detail-content {
      width: auto;
      padding-left: 0;
    }

  }


  @media only screen and (max-width: $size-S) {

    &-main {
      padding: 0 30px;
    }

  }


}