//
// historical buildings dms
//


.frame-type-list {
  header {
    padding: 40px 60px 0 60px;
  }

  @media only screen and (max-width: $size-S) {
    header {
      padding: 40px 30px 0 30px;
    }
  }
}

// list view
.history-list-view-dms {
  padding: 40px 60px;

  ul {
    @include ul-reset;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    margin: -10px;
  }

  li {
    padding: 10px;
    width: 25%;
  }

  figure {
    display: flex;
    flex-flow: column;
    background: $white;
    height: 100%;

    img {
      display: block;
    }
  }

  figcaption {
    padding: 5px 10px;

    a {
      text-decoration: none;
      color: $black;
    }

    span {
      display: block;

      &.title {
        font-weight: 700;
      }
    }
  }


  @media only screen and (max-width: $size-XL2) {
    li {
      width: calc(100% / 3);
    }
  }

  @media only screen and (max-width: $size-L) {
    li {
      width: calc(100% / 2);
    }
  }

  @media only screen and (max-width: $size-S2) {
    li {
      width: 100%;
    }
  }

}


// detail
.history-detail-view-dms {
  padding: 40px 60px;
  color: $white;

  header.history-detail {
    padding-top: 0;
  }

  .history-detail-images {

    ul {
      display: flex;
      flex-flow: row wrap;
      overflow: hidden;
      margin: -4px;
    }

    li {
      padding: 4px;
      width: 7%;

      img {
        display: block;
      }

      @media only screen and (max-width: $size-L) {
        width: 14%;
      }

      @media only screen and (max-width: $size-S) {
        width: 20%;
      }
    }
  }

  .history-detail-documents {

    li:not(:first-child) {
      margin-top: 10px;
    }

    a {
      display: inline-block;
      text-decoration: none;
      background: url("../Images/icon_pdf.png") no-repeat;
      background-size: 30px;
      padding: 5px 0 5px 40px;
    }

  }


  .history-detail-owner,
  .history-detail-history,
  .history-detail-images,
  .history-detail-documents,
  .history-detail-videos {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $white;

    h3 {
      margin: 0 0 15px 0;
    }

    ul {
      @include ul-reset;
    }

    .bodytext {
      max-width: 700px;
    }

  }



}

.history-detail-view-dms-main {
  display: flex;

  .history-detail-main-image {
    width: 40%;
    padding-top: 10px;
  }

  .history-detail-text {
    width: 60%;
    padding-left: 50px;

    header.history-detail {
      padding-left: 0;
    }
  }


  @media only screen and (max-width: $size-L) {
    display: block;

    .history-detail-main-image {
      width: auto;
    }

    .history-detail-text {
      width: auto;
      padding-left: 0;
    }
  }
}