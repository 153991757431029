//
// timeline js CSS
//

.tx_pvtimeline {
  padding-top: 20px;
}

.storyjs-embed {
  background: none !important;
  background-color: transparent !important;
  height: calc(100vh - 60px);
}

.vco-storyjs {
  background-color: transparent !important;
  font-family: $baseFont !important;
  @include font-size(16);

  .text {
    @include font-size(16);
    line-height: 1.4;
    color: $white !important;
  }
}

.vco-storyjs h1,
.vco-storyjs h2,
.vco-storyjs h3,
.vco-storyjs h4,
.vco-storyjs h5,
.vco-storyjs h6 {
  color: $white !important;
  font-family: $baseFont !important;
}

.vco-storyjs .vco-feature p,
.vco-slider .slider-item .content .content-container .media .media-wrapper .media-container .caption,
.vco-slider .slider-item .content .content-container .media .media-wrapper .media-container .credit {
  color: $white !important;
}

.vco-slider .nav-previous:hover,
.vco-slider .nav-next:hover {
  color: $white !important;
}

.vco-timeline .vco-navigation .timenav .content .marker .flag .flag-content h3 {
  color: #000000 !important;
}

.vco-timeline .vco-navigation .timenav .content .marker.active .flag .flag-content h3,
.vco-timeline .vco-navigation .timenav .content .marker.active .flag-small .flag-content h3 {
  color: $red-dark !important;
}

.vco-slider .slider-item .content .content-container .text .container {
  line-height: 1.2 !important;
  h2 {
    display: none;
  }
}

.vco-slider .slider-item .content .content-container .media {
  width: auto !important;
}

#storyjs-timeline.vco-storyjs p {
  font-family: $baseFont !important;
}