//
// slider page text block
//

.slider-text-block {
  margin: 20px 0 0 50%;
  padding: 50px 200px 30px 30px;
  h1 {
    color: $white;
    @include font-size(48);
    font-weight: 300;
    margin:  0 0 15px 0;
  }
  p {
    color: $white;
    @include font-size(18);
  }
  @media only screen and (max-width: $size-XXL) {
    margin: 50px 0 0 0;
    padding: 0 80px;
  }
  @media only screen and (max-width: $size-S) {
    padding: 0 20px;
    h1 {
      @include font-size(34);
    }
  }
}