//
// Body CSS
//

body {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: $baseFont;
  @include font-size(16);
  background: url("../Images/bg_main.jpg") no-repeat $black;
  background-attachment: fixed;

  &.start-page,
  &.pid-0 {
    background-image: url("../Images/bg_start.jpg");
  }

  &.living-vohburg,
  &.page-id-14 {
    background-image: url("../Images/bg_lebendiges_vohburg.jpg");
  }

  background-size: cover;

  @media only screen and (max-width: $size-XXL) {
    &.start-page,
    &.pid-0 {
      background: url("../Images/bg_start_small.jpg") no-repeat;
      background-size: cover;
    }
  }
  @media only screen and (max-width: $size-S) {
    &.start-page,
    &.pid-0 {
      background: url("../Images/bg_main.jpg") no-repeat;
    }
  }

}


h1.logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 195px;
  height: 101px;
  margin: 0;
  background: url("../Images/logo_small.svg") 100%/100% no-repeat;
  overflow: hidden;
  z-index: 9;
  text-indent: -99999px;

  @media only screen and (max-width: $size-S) {
    width: 120px;
    height: auto;
  }

}

p {
  margin: 0 0 1em 0;
  &:last-child {
    margin: 0;
  }
}

h1 {
  color: $white;
  @include font-size(48);
  font-weight: 300;
  margin: 0;
}

.iframe-container {
  max-width: 100%;
  height: auto;
}

#responsive-iframe {
  width: 100%;
  /*height: 100%;*/
}