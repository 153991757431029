//
// Header CSS
//

.page-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(#4D4F54 0%, #36383C 100%);
  z-index: 999;

  h1 {
    color: $white;
    @include font-size(48);
    font-weight: 300;
    margin: 0;
  }
}