//
// living Vohburg
//

.living-vohburg {

  .main-header {
    padding: 240px 30px 0 40px;

    h1 {
      @include font-size(128);
      line-height: 0.9;
      float: left;
    }

    h2 {
      @include font-size(50);
      line-height: 0.9;
      color: $white;
      font-weight: 300;
      float: left;
      margin-left: -92px;
      margin-top: 115px;
      width: 400px;
    }


    @media only screen and (max-width: $size-XXL) {
      padding: 60px 30px 0 40px;
    }


    @media only screen and (max-width: $size-XL) {
      h1 {
        @include font-size(80);
        float: none;
      }
      h2 {
        @include font-size(40);
        margin: 0;
        float: none;
      }
    }
    @media only screen and (max-width: $size-XS) {
      h1 {
        @include font-size(60);
      }
      h2 {
        @include font-size(35);
      }
    }
  }


  .start {
    background: red;

    a {
      display: block;
      position: absolute;
      right: 20%;
      top: 75%;
      color: $white;
      text-decoration: none;
      text-align: center;
      width: 200px;
      @include font-size(24);

      &::before {
        position: absolute;
        content: '';
        top: -160px;
        left: -26px;
        cursor: pointer;
        opacity: .6;
        background: url("../Images/arrow_circle_right.svg") no-repeat;
        background-size: 240px;
        width: 200px;
        height: 160px;
        transition: opacity 3ms;
      }

      &:hover {
        &::before {
          opacity: .9;
        }
      }
    }

    @media only screen and (max-width: $size-XL) {
      position: relative;
      margin-top: 220px;
    }
  }
}