//
// E-Learning CSS
//

.iframe-container {

  iframe {
    border: none;
    height: calc(100vh - 45px);
  }

}