//
// Main CSS
//

main {

  .csc-header-n1 {
    padding: 20px 30px 0 30px;
    min-height: 100px;
  }

  .header-image {
    display: none;
    margin-top: 40px;

    @media only screen and (max-width: $size-S) {
      display: block;
      img {
        display: block;
      }
    }
  }

  @media only screen and (min-width: $size-M) {

  }

}


// special text display on legal
.pid-6 {

  main {
    padding: 40px;
    max-width: 1000px;
    margin: auto;
    color: $white;

    h1 {
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: $size-S) {
    main {
      padding: 40px 20px;
    }
  }

}