//
// Nav Start CSS
//

.nav-start-outer {
  @include center();
  max-width: 866px;

  ul {
    @include ul-reset;
  }

  li {
    display: block;
    position: relative;
    float: left;
    width: 433px;
    height: 220px;
    z-index: 2;
  }

  a {
    display: block;
    text-decoration: none;
    color: $white;
    min-height: 220px;


    span {
      display: block;
    }

    .title {
      @include font-size(34);
      text-transform: uppercase;
    }

    .subtitle {
      @include font-size(34);
    }

    .description {
      @include font-size(20);
      line-height: 1.3;
    }

  }

  li {

    &:nth-child(1) a {
      padding: 12px 90px 90px 50px;
      background: url("../Images/btn_civil_info.svg") no-repeat;
      &:hover {
        background-image: url("../Images/btn_civil_info_act.svg");
      }
    }
    &:nth-child(2) a {
      padding: 12px 50px 90px 90px;
      background: url("../Images/btn_history.svg") no-repeat;
      &:hover {
        background-image: url("../Images/btn_history_act.svg");
      }
    }
    &:nth-child(3) a {
      padding: 90px 90px 30px 50px;
      background: url("../Images/btn_tourism.svg") no-repeat;
      &:hover {
        background-image: url("../Images/btn_tourism_act.svg");
      }
    }
    &:nth-child(4) a {
      padding: 90px 50px 10px 90px;
      background: url("../Images/btn_docu.svg") no-repeat;
      &:hover {
        background-image: url("../Images/btn_docu_act.svg");
      }
    }

  }

  @media only screen and (max-width: $size-L) {
    position: relative;
    left: auto;
    top: auto;
    margin-top: 150px;
    transform: none;


    li {
      float: none;
      width: 100%;
      height: auto;
      margin-bottom: 15px;

      a {
        overflow: hidden;
        height: 130px;
        margin: 5px 20px 0 20px;
        border: 1px solid $white;
        background: none;
        padding: 10px 15px !important;
        width: auto;
        float: none;
        min-height:20px;

        &:hover {
          background-image: none !important;
        }
      }

      &:nth-child(1) a {
        background: $elearning;
      }
      &:nth-child(2) a {
        background: $history;
      }
      &:nth-child(3) a {
        background: $tourism;
      }
      &:nth-child(4) a {
        background: $documentation;
      }

    }

  }
  @media only screen and (max-width: $size-S) {
    margin-top: 0;

    li {

      a {
        height: auto;
        padding: 0 10px;
        width: auto;
        float: none;
        border: none;
        margin: 5px 0 0 0;

        .title {
          @include font-size(30);
          line-height: 1.5;
        }

        .description {
          display: none;
        }

        &:hover {
          background-image: none;
        }
      }

    }

  }
}


// circles and other visual elements
.nav-start-inner {
  @include center();
  width: 866px;
  //position: relative;
  img {
    @include center();
    position: absolute;
    max-width: 656px;
    display: block;
    &.core-cirlce-red-blue {
      transform: none;
      margin-left: -165px;
      margin-top: -159px;
      max-width: 330px;
      -webkit-animation:spin 4s linear infinite;
      -moz-animation:spin 4s linear infinite;
      animation:spin 4s linear infinite;
    }
    &.center-button {
      max-width: 247px;
      z-index: 9;
    }
  }
  @media only screen and (max-width: $size-L) {
    width: auto;
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    img {
      display: none;
    }
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }