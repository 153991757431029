/* Slick Slider CSS */

/*** Slick Slider ***/
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: #fff url("../Images/ajax-loader.gif") center center no-repeat; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }
[dir="rtl"] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }


/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 60px; width: 60px; line-height: 0; font-size: 0; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -30px; padding: 0; border: none; outline: none; }
//.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }
//.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }
//.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

/*
.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }
[dir="rtl"] .slick-prev { left: auto; right: -25px; }
.slick-prev:before { content: "←"; }
[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }
[dir="rtl"] .slick-next { left: -25px; right: auto; }
.slick-next:before { content: "→"; }
[dir="rtl"] .slick-next:before { content: "←"; }
*/

.slick-prev {
  left: -60px;
  background: url("../Images/arrow_circle_right.svg") center center no-repeat;
  background-size: 60px;
  transform: rotate(180deg);
}

.slick-next {
  right: -60px;
  background: url("../Images/arrow_circle_right.svg") center center no-repeat;
  background-size: 60px;
}

.slick-prev,
.slick-next {
  opacity: .7;

  &:hover {
    opacity: 1;
  }
}


/* Dots */
.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: 0; list-style: none; display: block; text-align: center; padding: 0; width: 100%; }
.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }
.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 5px; cursor: pointer; }
.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }
.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }
.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; font-family: "slick"; font-size: 38px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }


// customizing

.slider {
  margin: 0 80px;
  figure {
    padding: 0 10px;
    a {
      display: block;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    figcaption {
      a {
        color: $white;
        text-decoration: none;
        background: $blue-plain;
        text-align: center;
        @include font-size(22);
        padding: 8px 10px;
        border-radius:  0  0 10px 10px;
        &:hover {
          background: $yellow;
        }
      }
    }
    &:hover figcaption a {
      background: $yellow;
    }
  }

  @media only screen and (max-width: $size-M) {
    figure {
      figcaption a {
        @include font-size(18);
      }
    }
  }
  @media only screen and (max-width: $size-S) {
    margin: 0 20px;
  }
}